import { css } from 'styled-components';

export const AppWrapperStyle = css`
  background-color: transparent;
  /* color: black; */
  text-align: ${(props) => props.theme.config.align};

  .wayf_alternatives {
    .simpleLink {
      cursor: pointer;
      background: none !important;
      border: none;
      padding: 0 !important;
      text-decoration: underline;
      font-size: 1em;
      cursor: pointer;
    }
  }

  .wayf_ZipCodeInput {
    input {
      font-size: 1.5em;
      letter-spacing: 0.5em;
      padding: 0.5em 0 0.5em 0.5em;
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 6em;
      border: solid 3px ${(props) => props.theme.config.color};
    }
    input:focus {
      outline: 0;
    }
  }

  .wayf_UaiInput {
    input {
      font-size: 1.5em;
      padding: 0.5em 0.25em;
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 6em;
      border: solid 3px ${(props) => props.theme.config.color};
    }
    button {
      font-size: 1.5em;
      padding: 0.5em;
      text-align: center;
      border: none;
      border-radius: 5px;
      background-color: white;
      color: ${(props) => props.theme.config.color};
      border: solid 3px ${(props) => props.theme.config.color};
      margin-left: 0.5em;
      cursor:pointer;
    }
    button:hover {
      background-color: ${(props) => props.theme.config.color};
      color: white;
    }
    input:focus {
      outline: 0;
    }
  }

  .wayf_StructuresList {
    text-align: center;
    .wayf_instruction {
      color: black;
      margin-bottom: 4px;
    }
    .wayf_filters ul {
      list-style-type: none;
      padding: 0;
      display: block;
      border-top: dashed 1px grey;
      margin: 0 20px;
      padding: 10px 0;
      display: none;
      li {
        display: inline-block;
        font-size: 0.9em;
      }
      button {
        background-color: transparent;
        border: none;
        margin: 0 10px;
        white-space: nowrap;
      }
      button.wayf_on {
        color: ${(props) => props.theme.config.color};
      }
      button:before {
        content: ' ';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid 2px black;
        border-radius: 2px;
        margin-right: 5px;
        vertical-align: -10%;
      }
      button.wayf_on:before {
        border: solid 2px ${(props) => props.theme.config.color};
        background-color: ${(props) => props.theme.config.color};
      }
    }
    .wayf_list {
      list-style-type: none;
      padding: 0;
      height: 60vh;
      overflow-y: auto;
      color: black;
      padding: 0 15px;
      button {
        background-color: transparent;
        border: none;
        padding: 0.25em 1em;
        margin: 0.25em 0;
        border-radius: 5px;
        font-size: 1em;
        border: solid 1px #eeeeee;
        font-weight: bold;
        .wayf_id {
          font-size: 0.9em;
          font-weight: normal;
        }
      }
      button:hover {
        background-color: ${(props) => props.theme.config.color};
        color: white;
      }
    }
    .wayf_close {
      text-decoration: none;
    }
  }

  .wayf_StructureLink {
    .wayf_back {
      background-color: white;
      color: ${(props) => props.theme.config.color};
      border: solid 2px ${(props) => props.theme.config.color};
      text-decoration: none;
      border-radius: 100px;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      line-height: 17px;
      text-align: center;
      padding: 0;
    }
    .wayf_structure {
      background-color: transparent;
      border: none;
      padding: 0.5em 1em;
      margin: 1em 0;
      border-radius: 5px;
      font-size: 1em;
      border: solid 1px #eeeeee;
      font-weight: bold;
      width: auto;
      .wayf_id {
        font-size: 0.9em;
        font-weight: normal;
      }
    }
    .wayf_id {
      font-size: 0.9em;
      font-weight: normal;
    }
    .wayf_link {
      ${(props) => `background-color: ${props.theme.config.color};`}
      color: white;
      text-decoration: none;
      padding: 5px 1em;
      border-radius: 5px;
      font-size: 1.5em;
    }
  }

  .wayf_Popin {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .wayf_overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
    .wayf_close {
      position: absolute;
      display: block;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: transparent;
      padding: 0;
      border: 0;
      font-size: 36px;
    }
    .wayf_inner {
      position: relative;
      background-color: white;
      width: 80%;
      min-width: 400px;
      max-width: 600px;
    }
  }
  &.wayf_dark {
    h1 {
      color: white;
    }
    .wayf_ZipCodeInput label.wayf_instruction {
      color: white;
    }
    .wayf_UaiInput label.wayf_instruction {
      color: white;
    }
    .wayf_StructuresList .wayf_instruction {
      color: black;
    }
    .wayf_StructuresList .wayf_filters ul button:before {
      border: solid 2px white;
    }
    .wayf_StructureLink .wayf_structure {
      color: white;
    }
    .wayf_list {
      color: white;
    }
    .wayf_alternatives {
      color: white;
      .simpleLink {
        color: white;
      }
    }
    .wayf_StructureLink {
      color: white;
      .wayf_link {
        background-color: white;
        ${(props) => `color: ${props.theme.config.color};`}
      }
    }
  }

  .wayf_error {
    color: red;
    padding: 10px 0;
    height: 40px;
  }
`;
