import { Structure } from 'components/core/models';
import axios from 'axios';

const WAYF_API = process.env.REACT_APP_API_URL;

const replaceApos = (str: string) => str.replaceAll(/&apos;/g, "'");

export class StructureService {
  getList = async (zipCode: string, page: number, limit: number) => {
    const url: string = `${WAYF_API}/structures?zipCode=${zipCode}&page=${page}&limit=${limit}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`, // no token for now
      },
    });

    const structures = response.data.structures as Array<Structure>;
    structures.map((structure: Structure): Structure => {
      structure.officialName = structure.officialName
        ? replaceApos(structure.officialName)
        : '';
      structure.category = structure.category
        ? replaceApos(structure.category)
        : '';

      return structure;
    });

    return {
      structures,
      pages: response.data.pages,
      currentPage: response.data.currentPage,
      total: response.data.total,
    };
  };

  getStructure = async (uai: string) => {
    const url = `${WAYF_API}/structures?uai=${uai}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`, // no token for now
      },
    });

    const structure: Structure = response.data as Structure;
    structure.officialName = structure.officialName
      ? replaceApos(structure.officialName)
      : '';
    structure.category = structure.category
      ? replaceApos(structure.category)
      : '';

    return structure;
  };
}
