import { SyntheticEvent, useEffect, useState, PropsWithChildren } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Structure } from 'components/core/models';

export const LinkText = (
  props: PropsWithChildren<{ to: string; title: string }>,
) => {
  return (
    <a
      href={props.to || '#'}
      target="_blank"
      title={props.title || ''}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

export const StructureLink = ({
  structure,
  onClose,
  onLink,
}: {
  structure: Structure;
  onClose: () => void;
  onLink: () => void;
}) => {
  const { t } = useTranslation();

  const [urlWithStructure, setUrlWithStructure] = useState<string>(
    `${structure.idEnt || ''}`,
  );

  const handleClose = (e: SyntheticEvent): void => {
    e.preventDefault();
    onClose();
  };

  const handleClick = (e: SyntheticEvent) => {
    // do not e.preventDefault();
    onLink();
  };

  useEffect(() => {
    const url: URL = new URL(structure.urlValue || process.env.REACT_APP_LC_URL || '');
    url.searchParams.set('structure', structure.uai);
    setUrlWithStructure(url.toString());
  }, [structure.idEnt, structure.uai, structure.urlValue]);

  return (
    <div className="wayf_StructureLink">
      <div>
        <button onClick={handleClose} className="wayf_back">
          &times;
        </button>
        <div className="wayf_structure">
          <div>{structure.officialName}</div>
          <div className="wayf_id">
            <span>{structure.category}</span>
            <> </>({structure.uai})
          </div>
        </div>
        {structure.idEnt ? (
          <div>
            {t('structureLink.garWithEnt.targetInformation') && (
              <p>t('structureLink.garWithEnt.targetInformation')</p>
            )}
            <a
              href={structure.urlValue}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
              className="wayf_link"
            >
              {t('structureLink.garWithEnt.button', {
                url: structure.urlValue,
              })}
            </a>
          </div>
        ) : structure.tne ? (
          <div className="wayf_tneMessage">
            <Trans
              t={t}
              i18nKey="structureLink.tne.instruction"
              components={{
                Link1: (
                  <LinkText to={t('structureLink.tne.link1')} title="TNE" />
                ),
                Link2: (
                  <LinkText to={t('structureLink.tne.link2')} title="Edugar" />
                ),
              }}
            />
          </div>
        ) : (
          <>
            {t('structureLink.notGAR.targetInformation') && (
              <p>t('structureLink.notGAR.targetInformation')</p>
            )}
            <a
              href={urlWithStructure}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClick}
              className="wayf_link"
            >
              {t('structureLink.notGAR.button', {
                url: structure.urlValue,
              })}
            </a>
          </>
        )}
      </div>
    </div>
  );
};
export default StructureLink;
