import { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Structure } from 'components/core/models';
import { StructureService } from 'components/core/services/structure.service';
import ZipCodeInput, {
  ZipCodeInputCallback,
  ZipCodeErrorCallback,
} from 'components/ZipCodeInput/ZipCodeInput';
import UaiInput, {
  UaiInputCallback,
  UaiErrorCallback,
} from 'components/UaiInput/UaiInput';
import StructuresList, {
  SelectCallback,
} from 'components/StructuresList/StructuresList';
import { StructureLink } from 'components/StructureLink/StructureLink';
import { Popin } from 'components/Popin/Popin';

import { AppWrapperStyle } from './App.style';
import styled, { ThemeProvider } from 'styled-components';
import { ErrorMsg } from 'components/ErrorMsg/ErrorMsg';

const AppWrapper = styled.div`
  ${AppWrapperStyle}
`;

const limit: number = 1000;

function App(config: any) {
  const [inputMode, setInputMode] = useState<string>('zipCode'); // or 'uai'
  const [status, setStatus] = useState<string>('form');
  const [zipCode, setZipCode] = useState<string>('');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [structures, setStructures] = useState<Array<Structure>>([]);
  const [structure, setStructure] = useState<Structure | null>(null);
  const [page] = useState<number>(1);
  const { t } = useTranslation();

  const handleZipCode: ZipCodeInputCallback = (zipCodeValue: string) => {
    setError(undefined);
    setZipCode(zipCodeValue);
  };
  const handleUai: UaiInputCallback = (uai) => {
    setError(undefined);
    // setUai(uaiValue);

    if (!uai) return;
    setError(undefined);
    const structureService = new StructureService();
    structureService
      .getStructure(uai)
      .then((ets: Structure) => {
        setStructure(ets);
        setStatus('link');
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError(new Error('noStructure'));
        } else {
          setError(error);
        }
      });
  };

  useEffect(() => {
    if (!zipCode) return;
    setError(undefined);
    const structureService = new StructureService();
    structureService
      .getList(zipCode, page, limit)
      .then((result) => {
        if (result.structures.length) {
          setStructures((prevList) => {
            const newList = [...prevList];
            result.structures.forEach(
              (item, i) => (newList[(page - 1) * limit + i] = item),
            );
            return newList;
          });
          setStatus('list');
        } else {
          setError(new Error('noStructures'));
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [zipCode, page]);

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
  };
  const handleSelect: SelectCallback = (structureSelected: Structure): void => {
    setStatus('link');
    setStructure(structureSelected);
  };
  const handleClose = () => {
    setStatus('form');
    setError(undefined);
  };
  const handleCloseLink = () => {
    setStatus('form');
    setError(undefined);
  };
  const handleLink = () => {
    setError(undefined);
    // setStatus('form');
  };
  const onChangeMode = (mode: string) => {
    setInputMode(mode);
    setError(undefined);
  };

  const onZipCodeError: ZipCodeErrorCallback = (code) => {
    setError(code ? new Error(code) : undefined);
  };
  const onUaiError: UaiErrorCallback = (code) => {
    setError(code ? new Error(code) : undefined);
  };

  return (
    <ThemeProvider theme={config}>
      <AppWrapper className={`wayf_${config.config.background}`}>
        {config.config.title && <h1>{config.config.title}</h1>}
        <div className="wayf_App">
          {(status === 'form' || status === 'list') && (
            <form onSubmit={handleSubmit}>
              <>
                {inputMode === 'zipCode' && (
                  <ZipCodeInput
                    onZipCode={handleZipCode}
                    onError={onZipCodeError}
                  />
                )}
                {inputMode === 'uai' && (
                  <UaiInput onUai={handleUai} onError={onUaiError} />
                )}

                <div className="wayf_error">
                  {error && (
                    <span>
                      <ErrorMsg error={error} namespace="errors" />
                    </span>
                  )}
                </div>

                <p className="wayf_alternatives">
                  {t('app.alternatives')}
                  <br />
                  {inputMode === 'zipCode' && (
                    <button
                      className="simpleLink"
                      onClick={() => {
                        onChangeMode('uai');
                      }}
                    >
                      {t('app.gotoUai')}
                    </button>
                  )}
                  {inputMode === 'uai' && (
                    <button
                      className="simpleLink"
                      onClick={() => {
                        onChangeMode('zipCode');
                      }}
                    >
                      {t('app.gotoZipCode')}
                    </button>
                  )}
                </p>
              </>
            </form>
          )}
          {!error && status === 'list' && (
            <Popin onClose={handleClose}>
              {status === 'list' && (
                <StructuresList
                  structures={structures}
                  onSelect={(structure) => handleSelect(structure)}
                />
              )}
            </Popin>
          )}
          {!error && status === 'link' && structure && (
            <StructureLink
              structure={structure}
              onClose={handleCloseLink}
              onLink={handleLink}
            />
          )}
        </div>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
