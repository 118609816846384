import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import 'index.css';

import App from 'components/App/App';
import { I18nextProvider } from 'react-i18next';
import i18n from 'translation/i18n';

const rootTag = document.getElementById('root') as HTMLElement;

interface OptionsInterface {
  title: string;
  color: string;
  background: string;
  align: string;
  filter: Array<string>;
  id_ressource: string;
}

const defaultOptions: OptionsInterface = {
  title: 'Accès aux ressources',
  color: '#333333',
  background: 'light',
  align: 'left',
  filter: ['colleges', 'lycees'],
  id_ressource: 'aqwzsx...',
};

if (rootTag) {
  // get json config from global variable called before react app,
  // name of variable is given from html attribute data-config
  // if undefined, get parameters are used
  const configAttribute: any = rootTag.getAttribute('data-config');

  let config: OptionsInterface = { ...defaultOptions };

  if (!configAttribute) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    config = {
      title: urlParams.get('title') || config.title,
      color: urlParams.get('color')
        ? '#' + urlParams.get('color')
        : config.color,
      background: urlParams.get('background') || config.background,
      align: urlParams.get('align') || config.align,
      filter: urlParams.get('filter')?.split(',') || config.filter,
      id_ressource: urlParams.get('id_ressource') || config.id_ressource,
    };
  } else {
    config = { ...config, ...JSON.parse(configAttribute) };
  }

  if (config.background === 'dark') {
    document.body.setAttribute('style', 'background: ' + config.color + ';');
  }

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <App config={config} />
      </React.StrictMode>
    </I18nextProvider>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
