import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorMsg = ({
  error,
  namespace,
}: {
  error: any; // depends of source...
  namespace: string;
}) => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState<string>('');
  // const apiError: ApiError = error as ApiError;

  useEffect(() => {
    console.log({ error });

    const codeToMessage = (code: string): string =>
      i18n.exists(`${namespace}.${code}`) ? t(`${namespace}.${code}`) : '';

    const errorToMessage = (error: any): string => {
      let message: string = '';
      if (error.code || error.name) {
        message = codeToMessage(error.code ? error.code : error.name);
      }
      if (!message && error.message) {
        message = codeToMessage(error.message);
      }
      if (!message) {
        message = t(`${namespace}.defaultError`);
      }
      return message;
    };

    if (error.errors && error.errors.length) {
      setMessage(
        error.errors.map((error: any) => errorToMessage(error)).join('<br/>'),
      );
    } else if (error) {
      setMessage(errorToMessage(error));
    }
  }, [error, namespace, i18n, t]);

  return message ? <span className="ErrorMsg">{message}</span> : <></>;
};
