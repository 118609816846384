import { useState, ChangeEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type ZipCodeInputCallback = (value: string) => void;
export type ZipCodeErrorCallback = (message: string) => void;

export const zipCodeFormat: RegExp = /^[0-9]{5}$/;

export const ZipCodeInput = ({
  onZipCode,
  onError,
}: {
  onZipCode: ZipCodeInputCallback;
  onError: ZipCodeErrorCallback;
}) => {
  const [inputValue, setInputValue] = useState<string>('_____');
  const { t } = useTranslation();

  const stripNonDigit = (str: string): string => {
    return str.replace(/[^0-9]/g, '');
  };

  const handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    onZipCode('');
    setInputValue('_____');
    e.currentTarget.value = '_____';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let str = e.currentTarget.value;
    str = stripNonDigit(str);

    if (str.length === 5) {
      if (!!str.match(zipCodeFormat) && str !== '00000') {
        e.target.blur();
        onZipCode(str);
        onError('');
      } else {
        onZipCode('');
        onError('incorrectZipCodeFormat');
      }
    } else {
      onError('');
    }

    str = str.padEnd(5, '_');
    setInputValue(() => str);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 8) {
      e.preventDefault();
      let str = e.currentTarget.value;
      str = stripNonDigit(str);
      str = str.slice(0, -1);
      str = str.padEnd(5, '_');
      setInputValue(() => str);
      onError('');
    }
  };

  return (
    <div className="wayf_ZipCodeInput">
      <p>
        {t('zipCodeInput.instruction') && (
          <label className="wayf_instruction" htmlFor="zipcode">
            {t('zipCodeInput.instruction')}
          </label>
        )}
      </p>
      <input
        type="text"
        name="zipcode"
        value={inputValue}
        autoComplete="off"
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        data-testid="ZipCodeInput-input"
      />
    </div>
  );
};

export default ZipCodeInput;
