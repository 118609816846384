import { useState, useEffect, SyntheticEvent } from 'react';
import { Structure } from 'components/core/models';
import { useTranslation } from 'react-i18next';

export type SelectCallback = (structureSelected: Structure) => void;

export const StructuresList = ({
  structures,
  onSelect,
}: {
  structures: Array<Structure>;
  onSelect: SelectCallback;
}) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState('');
  const [filters, setFilters] = useState<string[]>([]);
  const [selectedInput] = useState(t('structuresList.filter') || '');

  const [structuresFiltered, setStructuresFiltered] =
    useState<Array<Structure>>();

  const handleSelect = (e: SyntheticEvent, structure: Structure): void => {
    e.preventDefault();
    onSelect(structure);
  };

  const handleInputChange = (e:any) => { 
    const searchTerm = e.target.value;
    e.preventDefault();
    setStructuresFiltered(
      structures.filter((structure) => structure.officialName.indexOf(searchTerm) > -1 ),
    );
  }

  const handleFilterSelect = (_category: string) => {
    setCategory(_category);
    setStructuresFiltered(
      structures.filter((structure) => _category === '' || structure.category === _category),
    );
  };

  useEffect(() => {
    if (!structures) return;
    setStructuresFiltered(structures);
    const f: string[] = structures
      .reduce((acc: Array<string>, curr: Structure) => {
        if (curr.category && !acc.includes(curr.category)) acc.push(curr.category);
        return acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    if (f) setFilters(f);
  }, [structures]);

  return (
    <div className="wayf_StructuresList">
      {t('structuresList.instruction') && (
        <p className="wayf_instruction">{t('structuresList.instruction')}</p>
      )}
      <input type="text" onChange={handleInputChange} placeholder= {selectedInput} />
      <ul className="wayf_list">
        {structuresFiltered &&
          structuresFiltered.map((structure) => (
            <li key={structure.uuid}>
              <button
                onClick={(e: SyntheticEvent) => handleSelect(e, structure)}
              >
                <div>{structure.officialName}</div>
                <span className="wayf_id">
                  {category === '' ? <span>{structure.category}</span> : ''}
                  <> </>({structure.uai})
                </span>
              </button>
            </li>
          ))}
      </ul>
      <div className="wayf_filters">
        <ul>
          <li>{t('structuresList.categories')} </li>
          <li>
            <button
              onClick={() => handleFilterSelect('')}
              className={category === '' ? 'wayf_on' : ''}
            >
              {t('structuresList.all')}
            </button>
          </li>
          {filters.map((filter) => (
            <li key={filter}>
              <button
                onClick={() => handleFilterSelect(filter)}
                className={category === filter ? 'wayf_on' : ''}
              >
                {filter}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StructuresList;
