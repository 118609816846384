import { SyntheticEvent, ReactNode } from 'react';

type PopinPropsType = {
  children: ReactNode;
  onClose: () => void;
};

export const Popin = ({ children, onClose }: PopinPropsType) => {
  const handleClose = (e: SyntheticEvent): void => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="wayf_Popin">
      <div className="wayf_overlay" onClick={handleClose}></div>
      <div className="wayf_inner">
        <button onClick={handleClose} className="wayf_close">
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};
export default Popin;
